import React from 'react'
import { RichText, Elements } from 'prismic-reactjs'
import LinkWrap from 'components/LinkWrap'

const linkResolver = (doc) => {
  const expectedDocTypes = {
    location: 'locations',
    customer: 'customers',
    merchant: 'merchants',
    news_post: 'about',
  }
  if (expectedDocTypes[doc.type]) {
    return `/${expectedDocTypes[doc.type]}/${doc.uid}/`
  }
  return false
}

const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key })
}

const htmlSerializer = function (type, element, content, children, key) {
  var props = {}
  switch (type) {
    // Add a class to paragraph elements
    // case Elements.paragraph:
    //   props = { className: 'paragraph-class' }
    //   return React.createElement('p', propsWithUniqueKey(props, key), children)

    // Don't wrap images in a <p> tag
    case Elements.image:
      props = { src: element.url, alt: element.alt || '' }
      return React.createElement('img', propsWithUniqueKey(props, key))

    // Add a class to hyperlinks
    case Elements.hyperlink:
      if (element.data.link_type === 'Document') {
        if (linkResolver(element.data)) {
          return (
            <LinkWrap
              key={propsWithUniqueKey(props, key)}
              to={linkResolver(element.data)}
            >
              {children}
            </LinkWrap>
          )
        } else {
          return React.createElement(
            'span',
            propsWithUniqueKey(props, key),
            '[unsupported link type]'
          )
        }
      } else {
        // external or file
        props = Object.assign({
          href: element.data.url,
          target: '_blank',
          rel: 'noopener',
        })
        return React.createElement(
          'a',
          propsWithUniqueKey(props, key),
          children
        )
      }

    // Return null to stick with the default behavior
    default:
      return null
  }
}

export default function RichTextRendered({ field }) {
  return <RichText render={field.raw} htmlSerializer={htmlSerializer} />
}
