import React from 'react'
import { graphql } from 'gatsby'
import BlockPost from 'components/blocks/BlockPost'
import BlockTextOnly from 'components/blocks/BlockTextOnly'
import BlockContact from 'components/blocks/BlockContact'
import Footer from 'components/Footer'
import Seo from 'components/Seo'

// markup
const Page = ({ data }) => {
  const post = data.prismicNewsPost
  let content = {
    titleLink: {
      to: '/about/',
      text: 'View All Posts',
    },
    blockTextOnly: {
      title: `More insights
      & stories`,
      bodyHtml: `<p>There’s more where that came from.
      Get in the know and check out our addtional insights</p>`,
      link: {
        to: '/about/',
        text: 'View All Posts',
      },
    },
  }
  content.post = {
    gatsbyImage: post.data.post_image,
    date: post.data.date,
    title: post.data.title.text,
    post: post.data.post_body,
  }
  const seoContent = {
    title: `新闻`,
  }

  return (
    <div>
      <Seo content={seoContent} />
      <BlockPost content={content.post} titleLink={content.titleLink} />
      <BlockTextOnly content={content.blockTextOnly} />
      <BlockContact />
      <Footer />
    </div>
  )
}

export default Page

export const query = graphql`
  query ($uid: String!) {
    prismicNewsPost(uid: { eq: $uid }) {
      data {
        date
        title {
          text
        }
        post_image {
          gatsbyImageData
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        post_body {
          html
          raw
          richText
        }
      }
    }
  }
`
