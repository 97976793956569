import React from 'react'
import ButtonLink from 'components/ButtonLink'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import RichTextRendered from 'components/RichTextRendered'
// import BodyWrap from 'components/BodyWrap'

const Component = ({ content, titleLink }) => {
  const { gatsbyImage, date, title, post } = content
  return (
    <section className='block-post bg--grey-2'>
      <Inview className='container fade-in-up'>
        <div className='title'>
          <h6 className='h6 color--grey-4'>
            新闻 &nbsp;&nbsp;/&nbsp;&nbsp; {date}
          </h6>
          <h1 className='h1 smaller'>{title}</h1>
          <ButtonLink to={titleLink.to} text={titleLink.text} />
        </div>
        <div className='post'>
          <ImageWrap image={gatsbyImage} />
          <div className='body'>
            <RichTextRendered field={post} />
            {/* <BodyWrap body={post} /> */}
          </div>
        </div>
      </Inview>
    </section>
  )
}

export default Component
